import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "cat-cpns-page" }
const _hoisted_2 = { class: "col-span-12 lg:col-span-12 xxl:col-span-12" }
const _hoisted_3 = { class: "intro-y grid grid-cols-12 gap-3 sm:gap-6" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "file box rounded-lg shadow pb-1 relative zoom-in" }
const _hoisted_6 = {
  href: "javascript:;",
  class: "w-5/5 lg:w-5/6 mx-auto"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitleMain = _resolveComponent("PageTitleMain")!
  const _component_LoadingDiv = _resolveComponent("LoadingDiv")!
  const _component_ModalPassingGrade = _resolveComponent("ModalPassingGrade")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageTitleMain, {
      "page-title": _ctx.pageTitle,
      "with-reload-button": "",
      "action-reload-button": _ctx.executeListCatCpns
    }, null, 8, ["page-title", "action-reload-button"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.pendingListCatCpns)
          ? (_openBlock(), _createBlock(_component_LoadingDiv, {
              key: 0,
              id: "loadingCatCpns"
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prettierListCatCpns, (catCpns, fakerKey) => {
          return (_openBlock(), _createElementBlock("div", {
            key: fakerKey,
            class: "intro-y col-span-6 sm:col-span-4 md:col-span-3 xxl:col-span-2",
            onClick: ($event: any) => (_ctx.onCardClick(catCpns.kode, catCpns.id))
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("a", _hoisted_6, [
                _createElementVNode("img", {
                  alt: "cat-cpns image",
                  class: "rounded-t-lg",
                  src: `/img/${catCpns.image}`
                }, null, 8, _hoisted_7)
              ]),
              _createElementVNode("span", {
                class: "block font-semibold text-md my-4 text-center truncate px-2",
                innerHTML: catCpns.title
              }, null, 8, _hoisted_8)
            ])
          ], 8, _hoisted_4))
        }), 128))
      ])
    ]),
    _createVNode(_component_ModalPassingGrade, {
      "cpns-code": _ctx.chosenCpnsCode,
      "jenis-cat": "pppk",
      "bidang-id": _ctx.chosenBidangId
    }, null, 8, ["cpns-code", "bidang-id"])
  ]))
}