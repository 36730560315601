import { AxiosRequestConfig, BaseResponse } from "@/services/types";
import { $api, configAppJson } from "@/services/constant";
import { HOST_API_V1 } from "@/services/env-init";
import {
  CatPembahasan,
  CatResult,
  DataCat,
  PayloadSubmitCat,
  ResponseGetPassingGrade,
  ResponseListCatCpns,
} from "./types";

const BASE_URL_TENTOR_CAT = HOST_API_V1 + "tentor/cat";

export const getTentorCatQuestion = async (
  type: string,
  config?: AxiosRequestConfig
): Promise<BaseResponse<DataCat>> => {
  const res = await $api().get(
    `${BASE_URL_TENTOR_CAT}/question/${type}`,
    config
  );
  return res.data;
};

export const postTentorCatSubmit = async (
  kodeExam: string,
  payload: PayloadSubmitCat
) => {
  const config = configAppJson();

  const res = await $api().post(
    `${BASE_URL_TENTOR_CAT}/submit/${kodeExam}`,
    payload,
    config
  );

  return res.data;
};

export const getTentorCatResult = async (
  kodeExam: string
): Promise<BaseResponse<CatResult>> => {
  const res = await $api().get(`${BASE_URL_TENTOR_CAT}/result/${kodeExam}`);
  return res.data;
};

export const getTentorCatPembahasan = async (
  kodeExam: string
): Promise<BaseResponse<CatPembahasan>> => {
  const res = await $api().get(`${BASE_URL_TENTOR_CAT}/pembahasan/${kodeExam}`);
  return res.data;
};

export const getTentorJenisCat = async (): Promise<
  BaseResponse<ResponseListCatCpns[]>
> => {
  const res = await $api().get(`${BASE_URL_TENTOR_CAT}/jenis/`);
  return res.data;
};

export const getTentorCatPassingGrade = async (
  type: string
): Promise<BaseResponse<ResponseGetPassingGrade>> => {
  const res = await $api().get(`${BASE_URL_TENTOR_CAT}/passing-grade/${type}`);
  return res.data;
};
