
import useWiseFetch from "@/composables/useWiseFetch";
import { BaseResponse } from "@/services/types";
import { getFeatureJenisCat } from "@/services/v1/feature/cat";
import { getMemberJenisCat } from "@/services/v1/member/cat";
import { getTentorJenisCat } from "@/services/v1/tentor/cat";
import type { ResponseListCatCpns } from "@/services/v1/member/cat/types";
import { gpf } from "@/utils/global-functions";
import { isMember, isTentor } from "@/utils/roles";
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onMounted,
  ref,
} from "vue";

const ModalPassingGrade = defineAsyncComponent(
  () => import(`./modal-passing-grade.vue`)
);

export default defineComponent({
  components: {
    ModalPassingGrade,
  },
  props: {
    pageTitle: {
      type: String,
      default: "",
    },
  },
  setup() {
    const chosenCpnsCode = ref("");
    const chosenBidangId = ref();

    const onCardClick = async (cpnsCode: string, bidangId: number) => {
      chosenCpnsCode.value = cpnsCode;
      chosenBidangId.value = bidangId;
      gpf.showModal("#modal-cat-cpns");
    };

    const {
      data: dataListCatCpns,
      pending: pendingListCatCpns,
      error: errorListCatCpns,
      execute: executeListCatCpns,
    } = useWiseFetch<BaseResponse<ResponseListCatCpns[]>>(() => {
      if (isMember.value) {
        return getMemberJenisCat("pppk");
      } else if (isTentor.value) {
        return getTentorJenisCat();
      } else {
        return getFeatureJenisCat("pppk");
      }
    });

    const prettierListCatCpns = computed(() => {
      const resData = dataListCatCpns.value?.response;
      return resData;
    });

    onMounted(executeListCatCpns);

    return {
      onCardClick,
      gpf,

      executeListCatCpns,
      pendingListCatCpns,
      prettierListCatCpns,

      chosenCpnsCode,
      chosenBidangId,
    };
  },
});
